/**
	水滴平台 -- 体检机构列表
	2021-12-27: 该表同时用于 美华保险   江晨翔
*/
export default {
	province_list: {
		110000: '北京市',
		120000: '天津市',
		130000: '河北省',
		140000: '山西省',
		150000: '内蒙古自治区',
		210000: '辽宁省',
		220000: '吉林省',
		230000: '黑龙江省',
		310000: '上海市',
		320000: '江苏省',
		330000: '浙江省',
		340000: '安徽省',
		350000: '福建省',
		360000: '江西省',
		370000: '山东省',
		410000: '河南省',
		420000: '湖北省',
		430000: '湖南省',
		440000: '广东省',
		450000: '广西壮族自治区',
		460000: '海南省',
		500000: '重庆市',
		510000: '四川省',
		520000: '贵州省',
		530000: '云南省',
		// 540000: '西藏自治区',
		610000: '陕西省',
		620000: '甘肃省',
		630000: '青海省',
		640000: '宁夏回族自治区'
		// 650000: '新疆维吾尔自治区',
		// 710000: '台湾省',
		// 810000: '香港特别行政区',
		// 820000: '澳门特别行政区',
		// 900000: '海外'
	},
	hospital_list: {
		1101001: '美年大健康北京西直门分院',
		1101002: '美年大健康北京大望路分院',
		1101003: '美年大健康北京牡丹园分院',
		1101004: '美年大健康北京宣武门分院',
		1101005: '美年大健康北京太阳宫分院',
		1101006: '美年大健康北京魏公村分院',
		1101007: '美年大健康北京酒仙桥分院',
		3403000: '美年大健康蚌埠分院',
		3416008: '美年大健康亳州蒙城分院',
		3416009: '美年大健康亳州分院',
		13040031: '美年大健康邯郸分院',
		15010044: '美年大健康呼和浩特玉泉分院',
		15010045: '美年大健康呼和浩特东汇分院',
		21060019: '美年大健康丹东分院',
		21070055: '美年大健康锦州太和分院',
		21090026: '美年大健康阜新分院',
		21100063: '美年大健康辽阳江北分院',
		21140046: '美年大健康葫芦岛分院',
		22020050: '美年大健康吉林丰满分院',
		32010075: '美年大健康南京江宁分院',
		32010076: '美年大健康南京鼓楼分院',
		32010077: '美年大健康南京河西分院',
		32010078: '美年大健康南京汉中路分院',
		32060082: '美年大健康南通钟秀分院',
		32060083: '美年大健康南通分院',
		33010032: '美年大健康杭州萧山分院',
		33010033: '美年大健康杭州临安分院',
		33010034: '美年大健康杭州钱江分院',
		33010035: '美年大健康杭州延安路分院',
		33010036: '美年大健康杭州桐庐分院',
		33010037: '美年大健康杭州滨江分院',
		33010038: '美年大健康杭州城北分院',
		33010039: '美年大健康杭州西溪分院',
		33010040: '美年大健康杭州黄龙分院',
		33020084: '美年大健康宁波慈溪分院',
		33020085: '美年大健康宁波鄞州分院',
		33020086: '美年大健康宁波分院',
		33040054: '美年大健康嘉兴分院',
		33080091: '美年大健康衢州分院',
		34010041: '美年大健康合肥滨湖分院',
		34010042: '美年大健康合肥包河分院',
		35020092: '美年大健康厦门湖里分院',
		35020093: '美年大健康厦门思明分院',
		36010072: '美年大健康南昌红谷分院',
		36010073: '美年大健康南昌青山湖分院',
		36010074: '美年大健康南昌美康分院',
		37010051: '美年大健康济南市中分院',
		37010052: '美年大健康济南明湖分院',
		37020087: '美年大健康青岛徐州路分院',
		37020088: '美年大健康青岛市南分院',
		37020089: '美年大健康青岛银海明珠分院',
		37130065: '美年大健康临沂万兴都分院',
		37130066: '美年大健康临沂金阳花园分院',
		37150064: '美年大健康聊城分院',
		41020056: '美年大健康开封分院',
		41030068: '美年大健康洛阳西工分院',
		41030069: '美年大健康洛阳高新分院',
		41030070: '美年大健康洛阳洛龙分院',
		42020047: '美年大健康黄石分院',
		42020048: '美年大健康阳新分院',
		42070024: '美年大健康鄂州分院',
		44030094: '美年大健康深圳美阳门诊部',
		44030095: '美年大健康深圳中港城门诊部',
		44030096: '美年大健康深圳美益康门诊部',
		44030097: '美年大健康深圳美年门诊部',
		44030098: '美年大健康深圳美兆门诊部',
		44030099: '美年大健康深圳红岭门诊部',
		44130049: '美年大健康惠州分院',
		44160043: '美年大健康河源分院',
		44190021: '美年大健康东莞南城分院',
		44190022: '美年大健康东莞虎门分公司',
		44190023: '美年大健康东莞市松山湖分院',
		45010079: '美年大健康南宁高新分院',
		45010080: '美年大健康南宁江南分院',
		45010081: '美年大健康南宁琅东分院',
		46010030: '美年大健康海口二分院',
		51010011: '美年大健康成都武侯分院',
		51010012: '美年大健康成都维康分院',
		51010013: '美年大健康成都金牛分院',
		51010014: '美年大健康成都科华分院',
		51010015: '美年大健康成都青羊分院',
		51010016: '美年大健康成都凯尔分院',
		51010017: '美年大健康成都天府三街分院',
		51060020: '美年大健康德阳分院',
		51070071: '美年大健康绵阳分院',
		51080027: '美年大健康广元分院',
		51110062: '美年大健康乐山分院',
		52010028: '美年大健康贵阳分院',
		52020067: '美年大健康六盘水分院',
		53010057: '美年大健康昆明广福分院',
		53010058: '美年大健康昆明国贸分院',
		53010059: '美年大健康昆明高新分院',
		62010061: '美年大健康兰州金茂分院',
		62100090: '美年大健康庆阳分院',
		65050029: '美年大健康哈密分院',
		120100114: '美年大健康天津友谊路分院',
		120100115: '美年大健康天津和平美佳分院',
		120100116: '美年大健康天津滨海分院',
		120100117: '美年大健康天津塘沽分院',
		120100118: '美年大健康天津鼓楼分院',
		120100119: '美年大健康天津八里台分院',
		130200111: '美年大健康唐山路南分院',
		130200112: '美年大健康唐山高新分院',
		140800157: '美年大健康运城分院',
		150500121: '美年大健康通辽滨河分院',
		150500122: '美年大健康通辽分院',
		220100158: '美年大健康长春南关分院',
		220100159: '美年大健康长春朝阳分院',
		220700102: '美年大健康松原分院',
		320200129: '美年大健康无锡华康分院',
		320200130: '美年大健康无锡建筑分院',
		320200131: '美年大健康无锡美华分院',
		320200132: '美年大健康无锡疗养院分院',
		320200133: '美年大健康无锡五爱广场分院',
		320500103: '美年大健康苏州东环分院',
		320500104: '美年大健康苏州美新分院',
		320500105: '美年大健康苏州相城分院',
		320500106: '美年大健康苏州长江路分院',
		360900155: '美年大健康宜春分院',
		370600151: '美年大健康烟台福田分院',
		370600152: '美年大健康烟台开发分院',
		370600153: '美年大健康烟台莱山分院',
		370700123: '美年大健康诸城分院',
		370700124: '美年大健康潍坊高新分院',
		370700125: '美年大健康潍坊分院',
		370900109: '美年大健康泰安分院',
		370900110: '美年大健康泰安温泉路分院',
		370900113: '美年大健康滕州分院',
		370900120: '美年大健康天水分院',
		410100164: '美年大健康郑州金水分院',
		410100165: '美年大健康郑州中州分院',
		410100166: '美年大健康郑州商都分院',
		410100167: '美年大健康郑州二七分院',
		410100168: '美年大健康郑州高新分院',
		410700148: '美年大健康长垣分院',
		410700149: '美年大健康新乡宝龙分院',
		410700150: '美年大健康新乡玫瑰园分院',
		411700174: '美年大健康驻马店分院',
		420100134: '美年大健康武汉硚口分院',
		420100135: '美年大健康武汉新洲分院',
		420100136: '美年大健康武汉光谷分院',
		420100137: '美年大健康武汉亿生分院',
		420100138: '美年大健康武汉汉口分院',
		420100139: '美年大健康武汉江夏分院',
		421300107: '美年大健康随州分院',
		430100160: '美年大健康长沙天心分院',
		430100161: '美年大健康长沙定王分院',
		430100162: '美年大健康长沙岳麓分院',
		430100163: '美年大健康长沙雨花分院',
		430200173: '美年大健康株洲分院',
		430300147: '美年大健康湘潭岳塘分院',
		430600156: '美年大健康岳阳分院',
		440300100: '美年大健康深圳美宝健康体检中心',
		440300101: '美年大健康深圳美盛门诊部',
		442000169: '美年大健康中山分院',
		500100170: '美年大健康重庆万象城分院',
		500100171: '美年大健康重庆江北分院',
		500100172: '美年大健康重庆石桥广场分院',
		510300175: '美年大健康自贡分院',
		510900108: '美年大健康遂宁分院',
		610100140: '美年大健康西安未央分院',
		610100141: '美年大健康西安建国分院',
		610100142: '美年大健康西安曲江分院',
		610100143: '美年大健康西安高新分院',
		610100144: '美年大健康西安科技路分院',
		630100146: '美年大健康西宁分院',
		650100126: '美年大健康乌鲁木齐水区分院',
		650100127: '美年大健康乌鲁木齐高新分院',
		650100128: '美年大健康乌鲁木齐沙区分院',
		654000154: '美年大健康伊犁分院'
	},
	city_list: {
		110100: '北京市',
		120100: '天津市',
		130100: '石家庄市',
		130200: '唐山市',
		130300: '秦皇岛市',
		130400: '邯郸市',
		130500: '邢台市',
		130600: '保定市',
		// 130700: '张家口市',
		130800: '承德市',
		130900: '沧州市',
		131000: '廊坊市',
		131100: '衡水市',
		140100: '太原市',
		// 140200: '大同市',
		// 140300: '阳泉市',
		// 140400: '长治市',
		// 140500: '晋城市',
		// 140600: '朔州市',
		140700: '晋中市',
		140800: '运城市',
		// 140900: '忻州市',
		141000: '临汾市',
		141100: '吕梁市',
		150100: '呼和浩特市',
		150200: '包头市',
		// 150300: '乌海市',
		150400: '赤峰市',
		150500: '通辽市',
		// 150600: '鄂尔多斯市',
		150700: '呼伦贝尔市',
		150800: '巴彦淖尔市',
		// 150900: '乌兰察布市',
		// 152200: '兴安盟',
		// 152500: '锡林郭勒盟',
		// 152900: '阿拉善盟',
		210100: '沈阳市',
		210200: '大连市',
		210300: '鞍山市',
		210400: '抚顺市',
		210500: '本溪市',
		210600: '丹东市',
		210700: '锦州市',
		210800: '营口市',
		210900: '阜新市',
		211000: '辽阳市',
		211100: '盘锦市',
		211200: '铁岭市',
		211300: '朝阳市',
		211400: '葫芦岛市',
		220100: '长春市',
		220200: '吉林市',
		// 220300: '四平市',
		// 220400: '辽源市',
		// 220500: '通化市',
		// 220600: '白山市',
		220700: '松原市',
		// 220800: '白城市',
		// 222400: '延边朝鲜族自治州',
		230100: '哈尔滨市',
		// 230200: '齐齐哈尔市',
		// 230300: '鸡西市',
		// 230400: '鹤岗市',
		// 230500: '双鸭山市',
		// 230600: '大庆市',
		// 230700: '伊春市',
		230800: '佳木斯市',
		// 230900: '七台河市',
		// 231000: '牡丹江市',
		// 231100: '黑河市',
		// 231200: '绥化市',
		// 232700: '大兴安岭地区',
		310100: '上海市',
		320100: '南京市',
		320200: '无锡市',
		320300: '徐州市',
		320400: '常州市',
		320500: '苏州市',
		320600: '南通市',
		320700: '连云港市',
		320800: '淮安市',
		320900: '盐城市',
		321000: '扬州市',
		321100: '镇江市',
		321200: '泰州市',
		321300: '宿迁市',
		330100: '杭州市',
		330200: '宁波市',
		330300: '温州市',
		330400: '嘉兴市',
		330500: '湖州市',
		330600: '绍兴市',
		330700: '金华市',
		330800: '衢州市',
		// 330900: '舟山市',
		331000: '台州市',
		331100: '丽水市',
		340100: '合肥市',
		340200: '芜湖市',
		340300: '蚌埠市',
		340400: '淮南市',
		340500: '马鞍山市',
		340600: '淮北市',
		340700: '铜陵市',
		340800: '安庆市',
		341000: '黄山市',
		341100: '滁州市',
		341200: '阜阳市',
		341300: '宿州市',
		341500: '六安市',
		341600: '亳州市',
		// 341700: '池州市',
		341800: '宣城市',
		350100: '福州市',
		350200: '厦门市',
		350300: '莆田市',
		350400: '三明市',
		350500: '泉州市',
		350600: '漳州市',
		// 350700: '南平市',
		350800: '龙岩市',
		350900: '宁德市',
		360100: '南昌市',
		360200: '景德镇市',
		// 360300: '萍乡市',
		360400: '九江市',
		360500: '新余市',
		360600: '鹰潭市',
		360700: '赣州市',
		360800: '吉安市',
		360900: '宜春市',
		361000: '抚州市',
		361100: '上饶市',
		370100: '济南市',
		370200: '青岛市',
		370300: '淄博市',
		370400: '枣庄市',
		370500: '东营市',
		370600: '烟台市',
		370700: '潍坊市',
		370800: '济宁市',
		370900: '泰安市',
		371000: '威海市',
		371100: '日照市',
		371300: '临沂市',
		371400: '德州市',
		371500: '聊城市',
		// 371600: '滨州市',
		371700: '菏泽市',
		410100: '郑州市',
		410200: '开封市',
		410300: '洛阳市',
		410400: '平顶山市',
		410500: '安阳市',
		410600: '鹤壁市',
		410700: '新乡市',
		410800: '焦作市',
		410900: '濮阳市',
		411000: '许昌市',
		411100: '漯河市',
		411200: '三门峡市',
		411300: '南阳市',
		411400: '商丘市',
		// 411500: '信阳市',
		411600: '周口市',
		411700: '驻马店市',
		// 419000: '省直辖县',
		420100: '武汉市',
		420200: '黄石市',
		420300: '十堰市',
		420500: '宜昌市',
		420600: '襄阳市',
		420700: '鄂州市',
		420800: '荆门市',
		420900: '孝感市',
		421000: '荆州市',
		421100: '黄冈市',
		421200: '咸宁市',
		421300: '随州市',
		422800: '恩施土家族苗族自治州',
		// 429000: '省直辖县',
		430100: '长沙市',
		430200: '株洲市',
		430300: '湘潭市',
		430400: '衡阳市',
		// 430500: '邵阳市',
		430600: '岳阳市',
		430700: '常德市',
		430800: '张家界市',
		430900: '益阳市',
		431000: '郴州市',
		431100: '永州市',
		// 431200: '怀化市',
		431300: '娄底市',
		// 433100: '湘西土家族苗族自治州',
		440100: '广州市',
		440200: '韶关市',
		440300: '深圳市',
		440400: '珠海市',
		440500: '汕头市',
		440600: '佛山市',
		440700: '江门市',
		440800: '湛江市',
		// 440900: '茂名市',
		441200: '肇庆市',
		441300: '惠州市',
		441400: '梅州市',
		// 441500: '汕尾市',
		441600: '河源市',
		441700: '阳江市',
		441800: '清远市',
		441900: '东莞市',
		442000: '中山市',
		445100: '潮州市',
		// 445200: '揭阳市',
		445300: '云浮市',
		450100: '南宁市',
		450200: '柳州市',
		450300: '桂林市',
		// 450400: '梧州市',
		450500: '北海市',
		// 450600: '防城港市',
		450700: '钦州市',
		// 450800: '贵港市',
		450900: '玉林市',
		// 451000: '百色市',
		// 451100: '贺州市',
		// 451200: '河池市',
		// 451300: '来宾市',
		451400: '崇左市',
		460100: '海口市',
		// 460200: '三亚市',
		// 460300: '三沙市',
		460400: '儋州市',
		// 469000: '省直辖县',
		500100: '重庆市',
		// 500200: '县',
		510100: '成都市',
		510300: '自贡市',
		510400: '攀枝花市',
		510500: '泸州市',
		510600: '德阳市',
		510700: '绵阳市',
		510800: '广元市',
		510900: '遂宁市',
		511000: '内江市',
		511100: '乐山市',
		511300: '南充市',
		511400: '眉山市',
		511500: '宜宾市',
		// 511600: '广安市',
		511700: '达州市',
		511800: '雅安市',
		// 511900: '巴中市',
		512000: '资阳市',
		// 513200: '阿坝藏族羌族自治州',
		// 513300: '甘孜藏族自治州',
		// 513400: '凉山彝族自治州',
		520100: '贵阳市',
		520200: '六盘水市',
		520300: '遵义市',
		520400: '安顺市',
		520500: '毕节市',
		520600: '铜仁市',
		522300: '黔西南布依族苗族自治州',
		522600: '黔东南苗族侗族自治州',
		522700: '黔南布依族苗族自治州',
		530100: '昆明市',
		530300: '曲靖市',
		530400: '玉溪市',
		530500: '保山市',
		// 530600: '昭通市',
		// 530700: '丽江市',
		530800: '普洱市',
		530900: '临沧市',
		532300: '楚雄彝族自治州',
		532500: '红河哈尼族彝族自治州',
		532600: '文山壮族苗族自治州',
		532800: '西双版纳傣族自治州',
		// 532900: '大理白族自治州',
		// 533100: '德宏傣族景颇族自治州',
		// 533300: '怒江傈僳族自治州',
		// 533400: '迪庆藏族自治州',
		540100: '拉萨市',
		// 540200: '日喀则市',
		// 540300: '昌都市',
		// 540400: '林芝市',
		// 540500: '山南市',
		// 540600: '那曲市',
		// 542500: '阿里地区',
		610100: '西安市',
		// 610200: '铜川市',
		610300: '宝鸡市',
		610400: '咸阳市',
		// 610500: '渭南市',
		// 610600: '延安市',
		610700: '汉中市',
		610800: '榆林市',
		610900: '安康市',
		// 611000: '商洛市',
		620100: '兰州市',
		// 620200: '嘉峪关市',
		// 620300: '金昌市',
		620400: '白银市',
		// 620500: '天水市',
		620600: '武威市',
		// 620700: '张掖市',
		620800: '平凉市',
		620900: '酒泉市',
		621000: '庆阳市',
		// 621100: '定西市',
		621200: '陇南市',
		// 622900: '临夏回族自治州',
		// 623000: '甘南藏族自治州',
		630100: '西宁市',
		// 630200: '海东市',
		// 632200: '海北藏族自治州',
		// 632300: '黄南藏族自治州',
		// 632500: '海南藏族自治州',
		// 632600: '果洛藏族自治州',
		// 632700: '玉树藏族自治州',
		// 632800: '海西蒙古族藏族自治州',
		640100: '银川市',
		// 640200: '石嘴山市',
		// 640300: '吴忠市',
		640400: '固原市',
		// 640500: '中卫市',
		650100: '乌鲁木齐市',
		650200: '克拉玛依市',
		// 650400: '吐鲁番市',
		650500: '哈密市',
		// 652300: '昌吉回族自治州',
		// 652700: '博尔塔拉蒙古自治州',
		// 652800: '巴音郭楞蒙古自治州',
		652900: '阿克苏地区',
		// 653000: '克孜勒苏柯尔克孜自治州',
		653100: '喀什地区',
		// 653200: '和田地区',
		654000: '伊犁哈萨克自治州',
		// 654200: '塔城地区',
		// 654300: '阿勒泰地区',
		// 659000: '自治区直辖县级行政区划',
		// 710100: '台北市',
		// 710200: '高雄市',
		// 710300: '台南市',
		// 710400: '台中市',
		// 710500: '金门县',
		// 710600: '南投县',
		// 710700: '基隆市',
		710800: '新竹市',
		710900: '嘉义市',
		// 711100: '新北市',
		// 711200: '宜兰县',
		711300: '新竹县',
		// 711400: '桃园县',
		// 711500: '苗栗县',
		// 711700: '彰化县',
		711900: '嘉义县'
		// 712100: '云林县',
		// 712400: '屏东县',
		// 712500: '台东县',
		// 712600: '花莲县',
		// 712700: '澎湖县',
		// 712800: '连江县',
		// 810100: '香港岛',
		// 810200: '九龙',
		// 810300: '新界',
		// 820100: '澳门半岛',
		// 820200: '离岛'
	}
};